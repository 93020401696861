import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { Note, ScaleNote, notes, MajorIntervalLabels, MPIntervalLabels, mPIntervalLabels } from './notes';
import { Scale, scaleList, Ionian } from './scales';

type ScaleKey = keyof Scale;

const calculateIntervalUp = (root: Note, scale: Scale, interval: ScaleKey) => {
    let newValue: number = root.index + scale[interval].scaleRootOffset;
    newValue = newValue > 11 ? newValue - 12 : newValue;

    let MpIndex = interval as number + (scale.id - 1);
    MpIndex = MpIndex > 7 ? MpIndex - 7 : MpIndex;
    let mpIndex = interval as number + (scale.id - 6);
    mpIndex = mpIndex <= 0 ? mpIndex + 7 : mpIndex;

    let intervalNote: ScaleNote = {
        index: newValue,
        label: notes[newValue].label,
        interval: interval as number,
        intervalLabel: MajorIntervalLabels[interval as number],
        MpIntervalLabel: MPIntervalLabels[MpIndex],
        mpIntervalLabel: mPIntervalLabels[mpIndex],
        chord: scale[interval].chord
    }
    return intervalNote;
}

export interface ScalerState {
    notes: ScaleNote[];
    scale: Scale;
    rootNote: Note;
    family: [ScaleNote, Scale][];
    majorScale: [ScaleNote, Scale];
    minorScale: [ScaleNote, Scale];
}

const computeScale = (rootIndex: number, scale: Scale): ScaleNote[] => {
    let scaleNotes: ScaleNote[] = [];
    for (let i = 1; i <= 7; i++) {
        let intervalNote = calculateIntervalUp(notes[rootIndex], scale, i as keyof Scale);
        scaleNotes.push(intervalNote);
    }
    return scaleNotes;
}

const computeNext = (scaleNotes: ScaleNote[], scale: Scale) => {
    let nextNote = scaleNotes[1];
    let nextScale = scale.id !== 7 ? scaleList[scale.id + 1] : scaleList[1];
    nextScale = nextScale ?? scaleList[0];

    let result: [ScaleNote, Scale];
    result = [nextNote, nextScale];

    return result;
}

const getIonianRootNoteIndex = (scaleRootIndex: number, scale: Scale) => {
    let ionianRootIdx = scaleRootIndex;

    if (scale.id !== 1) {
        // how many steps below is ionian
        let ionianOffset = scale.id - 1;
        // how many intervals below is the ionian root
        let intervalOffset = 7 - ionianOffset;
        let sum = 0;
        // start with full count of semitones
        let minuend = 12;

        for (let i = 7; i > intervalOffset; i--) {
            // subtract interval's semitones-above-root from minuend and add result to sum
            sum += minuend - scale[i].scaleRootOffset;
            // reset minuend to interval's semitones-above-root
            minuend = scale[i].scaleRootOffset;

            // result is how many semitones need to be subtracted from current root to reach the ionian root
        }

        // subtract semitones from current root, add back 12 if we're about to go negative
        ionianRootIdx = scaleRootIndex < sum ? scaleRootIndex - sum + 12 : scaleRootIndex - sum;
    }

    return ionianRootIdx;
}

const computeFamily = (scaleNotes: ScaleNote[], scale: Scale) => {
    let family: [ScaleNote, Scale][];
    let ionianRootIdx = getIonianRootNoteIndex(scaleNotes[0].index, scale);

    let notes = computeScale(ionianRootIdx, Ionian);
    let currentScale = Ionian;
    family = [[notes[0], Ionian]];

    for (let i = 1; i < 7; i++) {
        let position = computeNext(notes, currentScale);
        family.push(position);
        notes.shift();
        notes.push(position[0]);
        currentScale = position[1];
    }

    return family;
}

const intialNotes = computeScale(0, Ionian);
const initialFamily = computeFamily(intialNotes, Ionian);

const initialState: ScalerState = {
    rootNote: notes[0],
    scale: Ionian,
    notes: intialNotes,
    family: initialFamily,
    majorScale: initialFamily[0],
    minorScale: initialFamily[5],
};

export const scalerSlice = createSlice({
    name: 'scaler',
    initialState,
    reducers: {
        changeRoot: (state, action: PayloadAction<string|number>) => {
            let newRoot = typeof action.payload === "number" ? action.payload : parseInt(action.payload);
            state.rootNote = notes[newRoot];
            state.notes = computeScale(newRoot, state.scale)
            state.family = computeFamily(state.notes, state.scale);
            state.majorScale = state.family[0];
            state.minorScale = state.family[5];
        },
        changeScale: (state, action: PayloadAction<string|number>) => {
            let newScaleIdx = typeof action.payload === "number" ? action.payload : parseInt(action.payload);
            state.scale = scaleList[newScaleIdx];
            state.notes = computeScale(state.rootNote.index, state.scale)
            state.family = computeFamily(state.notes, state.scale);
            state.majorScale = state.family[0];
            state.minorScale = state.family[5];
        },
        changeRootAndScale: (state, action: PayloadAction<[ScaleNote, Scale]>) => {
            let newRoot = action.payload[0].index;
            state.rootNote = notes[newRoot];
            state.scale = action.payload[1];
            state.notes = computeScale(newRoot, state.scale);
            state.family = computeFamily(state.notes, state.scale);
            state.majorScale = state.family[0];
            state.minorScale = state.family[5];
        }
    }
});

export const { changeRoot, changeScale, changeRootAndScale } = scalerSlice.actions;

export const selectNotes = (state: RootState) => state.scaler.notes;
export const selectRootNote = (state: RootState) => state.scaler.rootNote;
export const selectScale = (state: RootState) => state.scaler.scale;
export const selectFamily = (state: RootState) => state.scaler.family;
export const selectMajor = (state: RootState) => state.scaler.majorScale;
export const selectMinor = (state: RootState) => state.scaler.minorScale;

export default scalerSlice.reducer;