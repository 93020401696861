interface ScaleInterval {
    [key: string]: any,
    scaleRootOffset: number,
    chord: string
}

// {scaleRootOffset: 0, MpInterval: 1, mpInterval: 1}

export interface Scale {
    [key: string]: any,
    "id": number,
    "name": string,
    "displayName": string,
    "1": ScaleInterval,
    "2": ScaleInterval,
    "3": ScaleInterval,
    "4": ScaleInterval,
    "5": ScaleInterval,
    "6": ScaleInterval,
    "7": ScaleInterval
}

export const Ionian: Scale = {
    "id": 1,
    "name": "Ionian",
    "displayName": "Ionian",
    "1": {scaleRootOffset:0, chord: "M"},
    "2": {scaleRootOffset:2, chord: "m"},
    "3": {scaleRootOffset:4, chord: "m"},
    "4": {scaleRootOffset:5, chord: "M"},
    "5": {scaleRootOffset:7, chord: "M"},
    "6": {scaleRootOffset:9, chord: "m"},
    "7": {scaleRootOffset:11, chord: "dm7"}
}

export const Dorian: Scale = {
    "id": 2,
    "name": "Dorian",
    "displayName": "Dorian",
    "1": {scaleRootOffset:0, chord: "m"},
    "2": {scaleRootOffset:2, chord: "m"},
    "3": {scaleRootOffset:3, chord: "M"},
    "4": {scaleRootOffset:5, chord: "M"},
    "5": {scaleRootOffset:7, chord: "m"},
    "6": {scaleRootOffset:9, chord: "dm7"},
    "7": {scaleRootOffset:10, chord: "M"}
}

export const Phrygian: Scale = {
    "id": 3,
    "name": "Phrygian",
    "displayName": "Phrygian",
    "1": {scaleRootOffset:0, chord: "m"},
    "2": {scaleRootOffset:1, chord: "M"},
    "3": {scaleRootOffset:3, chord: "M"},
    "4": {scaleRootOffset:5, chord: "m"},
    "5": {scaleRootOffset:7, chord: "dm7"},
    "6": {scaleRootOffset:8, chord: "M"},
    "7": {scaleRootOffset:10, chord: "m"}
}

export const Lydian: Scale = {
    "id": 4,
    "name": "Lydian",
    "displayName": "Lydian",
    "1": {scaleRootOffset:0, chord: "M"},
    "2": {scaleRootOffset:2, chord: "M"},
    "3": {scaleRootOffset:4, chord: "m"},
    "4": {scaleRootOffset:6, chord: "dm7"},
    "5": {scaleRootOffset:7, chord: "M"},
    "6": {scaleRootOffset:9, chord: "m"},
    "7": {scaleRootOffset:11, chord: "m"}
}

export const Mixolydian: Scale = {
    "id": 5,
    "name": "Mixolydian",
    "displayName": "Mixolydian",
    "1": {scaleRootOffset:0, chord: "M"},
    "2": {scaleRootOffset:2, chord: "m"},
    "3": {scaleRootOffset:4, chord: "dm7"},
    "4": {scaleRootOffset:5, chord: "M"},
    "5": {scaleRootOffset:7, chord: "m"},
    "6": {scaleRootOffset:9, chord: "m"},
    "7": {scaleRootOffset:10, chord: "M"}
}

export const Aeolian: Scale = {
    "id": 6,
    "name": "Aeolian",
    "displayName": "Aeolian",
    "1": {scaleRootOffset:0, chord: "m"},
    "2": {scaleRootOffset:2, chord: "dm7"},
    "3": {scaleRootOffset:3, chord: "M"},
    "4": {scaleRootOffset:5, chord: "m"},
    "5": {scaleRootOffset:7, chord: "m"},
    "6": {scaleRootOffset:8, chord: "M"},
    "7": {scaleRootOffset:10, chord: "M"}
}

export const Locrian: Scale = {
    "id": 7,
    "name": "Locrian",
    "displayName": "Locrian",
    "1": {scaleRootOffset:0, chord: "dm7"},
    "2": {scaleRootOffset:1, chord: "M"},
    "3": {scaleRootOffset:3, chord: "m"},
    "4": {scaleRootOffset:5, chord: "m"},
    "5": {scaleRootOffset:6, chord: "M"},
    "6": {scaleRootOffset:8, chord: "M"},
    "7": {scaleRootOffset:10, chord: "m"}
}

interface ScaleList {
    [key: number]: Scale
}

export const scaleList: ScaleList = {
    1: Ionian,
    2: Dorian,
    3: Phrygian,
    4: Lydian,
    5: Mixolydian,
    6: Aeolian,
    7: Locrian,
}