export interface Note {
    index: number,
    label: string
}

export interface ScaleNote extends Note {
    interval: number,
    intervalLabel: string,
    MpIntervalLabel: string,
    mpIntervalLabel: string,
    chord: string
}

interface IntervalLabels {
    [key: number]: any
}

export const MajorIntervalLabels: IntervalLabels = {
    1: "I",
    2: "II",
    3: "III",
    4: "IV",
    5: "V",
    6: "VI",
    7: "VII"
}

export const MPIntervalLabels: IntervalLabels = {
    1: "I",
    2: "II",
    3: "III",
    4: "",
    5: "IV",
    6: "V",
    7: ""
}

export const mPIntervalLabels: IntervalLabels = {
    1: "I",
    2: "",
    3: "II",
    4: "III",
    5: "IV",
    6: "",
    7: "V"
}

export const notes: Note[] = [
    {
        "index": 0,
        "label": "A"
    },
    {
        "index": 1,
        "label": "A#"
    },
    {
        "index": 2,
        "label": "B"
    },
    {
        "index": 3,
        "label": "C"
    },
    {
        "index": 4,
        "label": "C#"
    },
    {
        "index": 5,
        "label": "D"
    },
    {
        "index": 6,
        "label": "D#"
    },
    {
        "index": 7,
        "label": "E"
    },
    {
        "index": 8,
        "label": "F"
    },
    {
        "index": 9,
        "label": "F#"
    },
    {
        "index": 10,
        "label": "G"
    },
    {
        "index": 11,
        "label": "G#"
    }
];