import React from 'react';
import { Scaler } from './features/scaler/Scaler';
import './App.css';
import logo from './scaler-text-path.svg';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} alt="scalr logo" className="App-logo" />
        <Scaler />
      </header>
    </div>
  );
}

export default App;
