import React from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { useAppSelector, useAppDispatch } from '../../app/hooks';
import {
    changeRootAndScale,
    selectRootNote,
    selectFamily
} from './scalerSlice';

const theme = createTheme({
  palette: {
    neutral: {
      main: '#64748B',
      contrastText: '#fff',
    },
  },
});

declare module '@mui/material/styles' {
  interface Palette {
    neutral: Palette['primary'];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    neutral?: PaletteOptions['primary'];
  }
}

// Update the Button's color prop options
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    neutral: true;
  }
}

export function ScaleFamily() {
  const family = useAppSelector(selectFamily);
  const rootNote = useAppSelector(selectRootNote);
  const dispatch = useAppDispatch();

  return (
    <ThemeProvider theme={theme}>
        {family.map((v, i) =>
        <Grid item xs={4} md={1} key={i}>
            <Button color="neutral" variant={v[0].index === rootNote.index ? "contained" : "text"} key={i} onClick={(e) => dispatch(changeRootAndScale(v))}>{v[0].label} {v[1].displayName}</Button>
        </Grid>
        
      )}
    </ThemeProvider>
      
  )
}