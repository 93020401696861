import React from 'react';
import { notes } from './notes';
import { scaleList } from './scales';
import { ScaleFamily } from './scaleNav';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
    changeRoot,
    changeScale,
    selectRootNote,
    selectScale,
    selectNotes,
    selectMajor,
    selectMinor
} from './scalerSlice';

const theme = createTheme({
    palette: {
        primary: {
            main: '#5924ff',
        },
        secondary: {
            main: '#ff0042aa',
            // dark: will be calculated from palette.secondary.main,
        },
        // Used by `getContrastText()` to maximize the contrast between
        // the background and the text.
        contrastThreshold: 3,
        // Used by the functions below to shift a color's luminance by approximately
        // two indexes within its tonal palette.
        // E.g., shift from Red 500 to Red 300 or Red 700.
        tonalOffset: 0.2,
    },
});

export function Scaler() {
    const scale = useAppSelector(selectScale);
    const rootNote = useAppSelector(selectRootNote);
    const scaleNotes = useAppSelector(selectNotes);
    const major = useAppSelector(selectMajor);
    const minor = useAppSelector(selectMinor);
    const dispatch = useAppDispatch();

    return (
        <div>
            <Grid sx={{flexGrow: 1}} container justifyContent="center" spacing={2} p={2}>
                <Grid item xs={12} md={10} ml={1}>
                    <Grid container justifyContent="left" spacing={2}>
                        <Grid item>
                            <FormControl sx={{minWidth: 80}}>
                                <InputLabel id="rootSelectLabel">Root Note</InputLabel>
                                <Select
                                labelId="rootSelectLabel"
                                id="rootSelect"
                                value={rootNote.index}
                                label="Root Note"
                                onChange={(e) => dispatch(changeRoot(e.target.value))}
                                >
                                    {notes.map((note) =>
                                        <MenuItem key={note.index} value={note.index}>
                                            {note.label}
                                        </MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <FormControl>
                                <InputLabel id="scaleSelectLabel">Scale</InputLabel>
                                <Select
                                labelId="scaleSelectLabel"
                                id="scaleSelect"
                                value={scale.id}
                                label="Scale"
                                onChange={(e) => dispatch(changeScale(e.target.value))}
                                >
                                    {Object.keys(scaleList).map((id) =>
                                        <MenuItem key={scaleList[parseInt(id)].id} value={scaleList[parseInt(id)].id}>
                                            {scaleList[parseInt(id)].displayName}
                                        </MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={10} ml={1}>
                    <ThemeProvider theme={theme}>
                        <List>
                            <ListItem disablePadding>
                                <ListItemText>
                                    <Typography variant="h6" component="span">Relevant major: </Typography>
                                    <Typography variant="h6" component="span" color="secondary">{major[0].label} {major[1].displayName}</Typography>
                                </ListItemText>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemText>
                                    <Typography variant="h6" component="span">Relevant natural minor: </Typography>
                                    <Typography variant="h6" component="span" color="secondary">{minor[0].label} {minor[1].displayName}</Typography>
                                </ListItemText>
                            </ListItem>
                        </List>
                    </ThemeProvider>
                </Grid>
                <Grid item xs={12} md={10}>
                    <TableContainer component={Paper}>
                        <Table aria-label="Scale table">
                            <TableHead>
                                <TableRow>
                                    <TableCell width="20%">Interval</TableCell>
                                    <TableCell align="center" width="20%">Note</TableCell>
                                    <TableCell align="center" width="20%">Chord</TableCell>
                                    <TableCell align="center" width="20%">
                                        M5 Interval
                                        <Tooltip enterTouchDelay={0} title={
                                            <React.Fragment>
                                            <List>
                                                <ListItem>
                                                    <ListItemText>
                                                        M5 - Major Pentatonic
                                                    </ListItemText>
                                                </ListItem>
                                            </List>
                                        </React.Fragment>
                                        }>
                                            <IconButton>
                                                <HelpOutlineIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell align="center" width="20%">
                                        m5 Interval
                                        <Tooltip enterTouchDelay={0} title={
                                            <React.Fragment>
                                            <List>
                                                <ListItem>
                                                    <ListItemText>
                                                        m5 - Minor Pentatonic
                                                    </ListItemText>
                                                </ListItem>
                                            </List>
                                        </React.Fragment>
                                        }>
                                            <IconButton>
                                                <HelpOutlineIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {scaleNotes.map((note) =>
                                <TableRow key={note.intervalLabel}>
                                    <TableCell component="th" scope="row" key={note.intervalLabel}>{note.intervalLabel}</TableCell>
                                    <TableCell align="center" key={note.interval}>{note.label}</TableCell>
                                    <TableCell align="center" key={note.chord}>{note.chord}</TableCell>
                                    <TableCell align="center" key={"majorPent"+note.MpIntervalLabel}>{note.MpIntervalLabel}</TableCell>
                                    <TableCell align="center" key={"minorPent"+note.mpIntervalLabel}>{note.mpIntervalLabel}</TableCell>
                                </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={10} mt={2} mb={10}>
                    <Grid container justifyContent="space-between" columns={{xs: 4, sm: 8, md: 12}}>
                        <ScaleFamily />
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );

    
}
